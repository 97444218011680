import { Calculadora001Lashing } from "./Calculadora001Lashing";

class Calculadora001 implements Shared.IEntity{

    id: number = 0;
    project: string = "";
    local: string = "";
    expectedLoadDate: string = new Date().toYYYYMMDD();
    typeTruck: string = "";
    truckCharacteristics: TruckCharacteristics = new TruckCharacteristics();
    cargoCharacteristics: CargoCharacteristics = new CargoCharacteristics();
    landTransportationConditions: LandTransportationConditions = new LandTransportationConditions();
    generalParticulars: GeneralParticulars = new GeneralParticulars();
    accelerations: Accelerations = new Accelerations();
    windCharacteristics: WindCharacteristics = new WindCharacteristics();

    lashings: Calculadora001Lashing[] = [];

    //CALCULADOS
    longSlidingMT: number = 0;
    transvSlidingMT: number = 0;
    transvTippingMTm: number = 0;

    constructor(model?: Calculadora001){

        if(!model)
            return;

        this.id = model.id;
        this.project = model.project;
        this.local = model.local;
        this.expectedLoadDate = model.expectedLoadDate ? model.expectedLoadDate.toDateYYYYMMDD() : "";
        this.typeTruck = model.typeTruck;
        
        this.truckCharacteristics = new TruckCharacteristics(model.truckCharacteristics);
        this.cargoCharacteristics = new CargoCharacteristics(model.cargoCharacteristics);
        this.landTransportationConditions = new LandTransportationConditions(model.landTransportationConditions);
        this.generalParticulars = new GeneralParticulars(model.generalParticulars);
        this.accelerations = new Accelerations(model.accelerations);
        this.windCharacteristics = new WindCharacteristics(model.windCharacteristics);

        this.lashings = model.lashings;

        this.longSlidingMT = model.longSlidingMT;
        this.transvSlidingMT = model.transvSlidingMT;
        this.transvTippingMTm = model.transvTippingMTm;
    }
}

class TruckCharacteristics {

    totalLength: number = 0;
    availableLength: number = 0;
    width: number = 0;
    heightOfTraillerCargo: number = 0;
 
    constructor(model?: TruckCharacteristics){
 
        if(!model)
            return;
 
        this.totalLength = model.totalLength;
        this.availableLength = model.availableLength;
        this.width = model.width;
        this.heightOfTraillerCargo = model.heightOfTraillerCargo;
    } 
 }

 class CargoCharacteristics {

    name: string = '';
    length: number = 0;
    width: number = 0;
    height: number = 0;
    mass: number = 0;
    appliedCoef: number = 0;
    leverArmOfStablenessLeft: number = 0;
    
    //SELECIONADO
    materialCargoFloor: string = '';
    friction: number = 0;
    
    //CALCULADO
    correctedMass: number = 0;
    leverArmOfTipping: number = 0;
    leverArmOfStablenessRigth: number = 0;
 
    constructor(model?: CargoCharacteristics){
 
        if(!model)
            return;
 
        this.name = model.name;
        this.length = model.length;
        this.width = model.width;
        this.height = model.height;
        this.mass = model.mass;
        this.appliedCoef = model.appliedCoef;
        this.leverArmOfStablenessLeft = model.leverArmOfStablenessLeft;
        
        //SELECIONADO
        this.materialCargoFloor = model.materialCargoFloor;
        this.friction = model.friction;
        
        //CALCULADO
        this.correctedMass = model.correctedMass;
        this.leverArmOfTipping = model.leverArmOfTipping;
        this.leverArmOfStablenessRigth = model.leverArmOfStablenessRigth;
    }
 }

 class LandTransportationConditions {

    maxHeight: number = 0;
    width: number = 0;
 
    constructor(model?: LandTransportationConditions){
 
        if(!model)
            return;
 
        this.maxHeight = model.maxHeight;
        this.width = model.width;
    } 
 }

 class GeneralParticulars {

    accelerationOfGravity: number = 9.81;
    airDensity: number =  1.23; //1.226;
 
    constructor(model?: GeneralParticulars){
 
        if(!model)
            return;
 
        this.accelerationOfGravity = model.accelerationOfGravity;
        this.airDensity = model.airDensity;
    } 
 }

 class Accelerations {

    methodForMotionsDefinition: number = 1;
    longAccel: number = 7.85;
    transvAccel: number = 4.91;

    //CALCULADO
    longAccelG: number = 0;
    transvAccelG: number = 0;
 
    constructor(model?: Accelerations){
 
        if(!model)
            return;
 
        this.methodForMotionsDefinition = model.methodForMotionsDefinition;
        this.longAccel = model.longAccel;
        this.transvAccel = model.transvAccel;

        //CALCULADO
        this.longAccelG = model.longAccelG;
        this.transvAccelG = model.transvAccelG;
    }
 }

 class WindCharacteristics {

    windVelocity: number = 25;
    transversalArea: number = 0;
    lateralArea: number = 0;
    
    //CALCULADO
    windPressure: number = 0;
    windForceLongDirectionFwx: number = 0;
    windForceTransvDirectionFwy: number = 0;
 
    constructor(model?: WindCharacteristics){
 
        if(!model)
            return;
 
        this.windVelocity = model.windVelocity;
        this.transversalArea = model.transversalArea;
        this.lateralArea = model.lateralArea;
        
        //CALCULADO
        this.windPressure = model.windPressure;
        this.windForceLongDirectionFwx = model.windForceLongDirectionFwx;
        this.windForceTransvDirectionFwy = model.windForceTransvDirectionFwy;
    }
 }

export { 
    Calculadora001, 
    TruckCharacteristics,
    CargoCharacteristics,
    LandTransportationConditions,
    GeneralParticulars,
    Accelerations
};