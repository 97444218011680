import { http } from '@/core/ApiConfig';

export class EnumeradoService{

  private _nomeControle: string = 'v1/enumerado';

  ListarMethodForMotionsDefinition(){
    return http.get(`${this._nomeControle}/MethodForMotionsDefinition`);
  }

  ListarDirections(){
    return http.get(`${this._nomeControle}/Direction`);
  }
}