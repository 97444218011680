
import { Component, Watch, Prop } from 'vue-property-decorator';
import { PageBase } from '@/core/models/shared';
import { Calculadora001Lashing } from '@/core/models/geral';
import { Calculadora001Service, EnumeradoService } from '@/core/services/geral';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';

@Component
export default class CadastroCalculadora001Lashing extends PageBase {
    @Prop() private value!: string;
    @Prop() item!: Calculadora001Lashing;
    @Prop({required: true}) friction!: number;

    dialog: boolean = false;
    valid: boolean = true;
    service: Calculadora001Service = new Calculadora001Service();
    $refs!: {
        form: HTMLFormElement
    }

    directions: any[] = [];
    enumeradoService: EnumeradoService = new EnumeradoService();
    numberFieldRules: any[] = [(v: any) => (!isNaN(parseFloat(v)) && v >= 0) || "Campo obrigatório"];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
            if (this.$refs.form)
                this.$refs.form.resetValidation();                        
        }
        else{
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }    

    mounted() {
        
    }

    Carregar(){
        this.enumeradoService.ListarDirections().then(
            res => this.directions = res.data,
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Calcular(){
        this.service.CalcularItem(this.friction, this.item).then(
            res => {
                let model = new Calculadora001Lashing(res.data);
                this.item.cs = model.cs;
                this.item.fx = model.fx;
                this.item.fy = model.fy;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate())            
            this.Salvou();            
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.$emit("fechou");
        this.dialog = false;
    }
}
