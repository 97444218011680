import { Vue } from "vue-property-decorator";
import { JSONToCSVConvertor, TableToExcel } from '@/assets/scripts/helper';
import { EnumPerfilUsuario } from "./Enumerados";

export class PageBase extends Vue {
    
    public enumPerfilUsuario = EnumPerfilUsuario;

    public sessionApp: any;
    public app: {usuarioId: number, nome: string, usuarioFoto: string, temaEscuro: boolean, login: string, perfilId: number} = {
        usuarioId: 0,
        nome: '',
        usuarioFoto: '',
        temaEscuro: false,
        login: '',
        perfilId: 0
    };

    constructor(){
        
        super();

        if (!localStorage.sessionApp){
            return;
        }

        this.sessionApp = JSON.parse(localStorage.sessionApp);

        this.app.usuarioId = this.sessionApp.dados.usuarioId;
        this.app.nome = this.sessionApp.dados.nome;
        this.app.usuarioFoto = this.sessionApp.dados.usuarioFoto;
        this.app.temaEscuro = this.sessionApp.dados.temaEscuro;
        this.app.login = this.sessionApp.dados.login;
        this.app.perfilId = this.sessionApp.dados.perfilId;
    }

    ExportarExcel(tabela: string, worksheet?: string){
      TableToExcel(tabela, worksheet);
    }

    JsonToCSV(JSONData: JSON, ReportTitle: string, ShowLabel: boolean){
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel);
    }

    MudarTema(temaEscuro: boolean){
        this.app.temaEscuro = temaEscuro;
        this.sessionApp.dados.temaEscuro = temaEscuro;
        localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
        this.$vuetify.theme.dark = temaEscuro;
    }

    Logout() {
        localStorage.clear();
        this.$vuetify.theme.dark = false;
        this.$router.push({name: 'Login'});
      }
}