import { http } from "@/core/ApiConfig";
import { Service } from "../shared";
import { Calculadora001, Calculadora001Lashing } from "@/core/models/geral";

export class Calculadora001Service extends Service{
    
    constructor(){
        super('v1/Calculadora');
    }

    Calcular(layoutId: number, model: Calculadora001){
        return http.post(`${this.GetNomeControle()}/Calcular/${layoutId}`, model);
    }

    CalcularItem(friction: number, model: Calculadora001Lashing){
        return http.post(`${this.GetNomeControle()}/CalcularItem`, model, {
            params: {
                friction
            }
        });
    }

    SecuringCapacity(model: Calculadora001Lashing[]){
        return http.post(`${this.GetNomeControle()}/SecuringCapacity`, model);
    }

    Results(model: Calculadora001){
        return http.post(`${this.GetNomeControle()}/Results`, model);
    }

    Detalhar001(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/Detalhar001`);
    }
}