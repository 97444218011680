import Vue from 'vue';

//SISTEMA
import MasterPage from "./shared/MasterPage.vue";
import TextSearch from "./shared/TextSearch.vue";
import AlterarSenha from './shared/AlterarSenha.vue';
import Avatar from "./shared/Avatar.vue";
import BreadCrumb from "./shared/BreadCrumb.vue";
import ListaHeader from './shared/ListaHeader.vue';
import RelatorioPage from './shared/RelatorioPage.vue';

//CALCULADORAS
import CadastroCalculadora001 from './geral/calculadora/Cadastro001.vue';
import CadastroCalculadora001Lashing from './geral/calculadora/Cadastro001Lashing.vue';

//CADASTROS
import CadastroMaterialGrupo from './geral/materialGrupo/Cadastro.vue'

import CadastroMaterial from './geral/material/Cadastro.vue'
import SelecionarMaterial from './geral/material/Selecionar.vue'

//CONFIGURACAO
import CadastroUsuario from './geral/usuario/Cadastro.vue';

//SISTEMA
Vue.component("master-page", MasterPage);
Vue.component("text-search", TextSearch);
Vue.component("alterar-senha", AlterarSenha);
Vue.component("avatar-component", Avatar);
Vue.component("breadCrumb-component", BreadCrumb);
Vue.component("lista-header", ListaHeader);
Vue.component("relatorio-page", RelatorioPage);

//CALCULADORAS
Vue.component("cadastro-calculadora001", CadastroCalculadora001);
Vue.component("cadastro-calculadora001-lashing", CadastroCalculadora001Lashing);

//CADASTROS
Vue.component("cadastro-materialGrupo", CadastroMaterialGrupo);

Vue.component("cadastro-material", CadastroMaterial);
Vue.component("seleciona-material", SelecionarMaterial);

//CONFIGURACAO
Vue.component("cadastro-usuario", CadastroUsuario);