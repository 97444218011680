import { MaterialGrupo } from "./MaterialGrupo";

export class Material implements Shared.IEntity {

    id: number = 0;
    nome: string = "";
    grupoId: number = 0;
    grupo!: MaterialGrupo;
    coeficienteSeco: number = 0;
    coeficienteMolhado: number = 0;

    constructor(model?: Material){

        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.grupoId = model.grupoId;
        this.grupo = model.grupo;
        this.coeficienteSeco = model.coeficienteSeco;
        this.coeficienteMolhado = model.coeficienteMolhado;
    }
}