import { EnumPerfilUsuario } from '@/core/models/shared/Enumerados';
import Vue from 'vue'
import VueRouter, { NavigationGuardNext, RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const enumPerfilUsuario = EnumPerfilUsuario;

const RequireAuth = (to: any, next: NavigationGuardNext<Vue>, perfilPermitido: number[]) => {
  var sessionApp: any;
  if(localStorage.sessionApp){
    sessionApp = JSON.parse(localStorage.sessionApp);
    if(perfilPermitido.find(x => x == sessionApp.dados.perfilId)){
      next();
    }
    else{
      next({ name:"Home" });
    }
  }
  else{
    next({ name: 'Login' });
  }
};

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/home' },

  { path: '/login', name: 'Login', component: () => import('../views/shared/Login.vue') },
  { path: '/recuperarSenha/:token', name: 'LoginToken', component: () => import('../views/shared/RecuperarSenha.vue') },
  { path: '/home', name: 'Home', component: () => import('../views/shared/Home.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumPerfilUsuario.Administrador, enumPerfilUsuario.Operador]) },
  
  //CALCULADORAS
  { path: '/calculadora/001', name: 'ListarCalculadora001', component: () => import('../views/geral/calculadora/Lista001.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumPerfilUsuario.Administrador, enumPerfilUsuario.Operador]) },
  { path: '/calculadora/001/:id/detalhar', name: 'DetalharCalculadora001', component: () => import('../views/geral/calculadora/Detalhar001.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumPerfilUsuario.Administrador, enumPerfilUsuario.Operador]) },

  //CADASTROS
  { path: '/cadastro/materialGrupo', name: 'ListarMaterialGrupo', component: () => import('../views/geral/materialGrupo/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumPerfilUsuario.Administrador, enumPerfilUsuario.Operador]) },
  { path: '/cadastro/material', name: 'ListarMaterial', component: () => import('../views/geral/material/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumPerfilUsuario.Administrador, enumPerfilUsuario.Operador]) },

  //CONFIGURACAO
  { path: '/configuracao/usuario', name: 'ListarUsuario', component: () => import('../views/geral/usuario/Lista.vue'), beforeEnter: (to, from, next) => RequireAuth(to, next, [enumPerfilUsuario.Administrador, enumPerfilUsuario.Operador]) },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
