
  import { PageBase } from '@/core/models/shared';
  import { UsuarioService } from '@/core/services/geral';
  import { AlertSimpleErr } from '@/core/services/shared/AlertService';
  import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
  
  
  @Component
  export default class MasterPage extends PageBase{
    @Prop() public icone!: string;
    @Prop() public titulo!: string;
  
    drawer: boolean = false;
    dialogAlterarSenha: boolean = false;
  
    calculadorasMenuButtons: any[] = [];
    geralMenuButtons: any[] = [];
    operacaoMenuButtons: any[] = [];
    relatorioMenuButtons: any[] = [];
    configMenuButtons: any[] = [];
  
    created() {
      this.$vuetify.theme.dark = this.app.temaEscuro;
      this.CarregarMenu();
    }
  
    CarregarMenu(){
      this.calculadorasMenuButtons = [
        {name:'LASHING CALCULATION FOR LAND TRANSPORTATION', icon:'mdi-file-document-edit', path:'/calculadora/001', restrito: this.UsuarioRestrito([this.enumPerfilUsuario.Administrador, this.enumPerfilUsuario.Operador])},
      ];
      this.geralMenuButtons = [
        {name:'Grupo Material', icon:'mdi-file-document-edit', path:'/cadastro/materialGrupo', restrito: this.UsuarioRestrito([this.enumPerfilUsuario.Administrador, this.enumPerfilUsuario.Operador])},
        {name:'Material', icon:'mdi-file-document-edit', path:'/cadastro/material', restrito: this.UsuarioRestrito([this.enumPerfilUsuario.Administrador, this.enumPerfilUsuario.Operador])},
      ];      
      this.configMenuButtons = [
        {name:'Usuário', icon:'mdi-account', path:'/configuracao/usuario', restrito: this.UsuarioRestrito([this.enumPerfilUsuario.Administrador, this.enumPerfilUsuario.Operador])},
      ];
    }
  
    GetAppVersion(){
      const pack = require('../../../package.json');
      return pack.version;
    }
  
    TemaEscuro(){
      const usuarioService = new UsuarioService();
        usuarioService.MudarTema(this.app.usuarioId).then(
          res => super.MudarTema(res.data),
          err => AlertSimpleErr("Aviso!", err)
        );
    }
  
    UsuarioRestrito(perilIds: any = []){
      if(perilIds.length > 0)
      {
        if(perilIds.find(x => x == this.app.perfilId))
          return false;
        else   
          return true;
      }
      else{
        return false;
      }
    }  
  }
  