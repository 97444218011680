export class MaterialGrupo implements Shared.IEntity {

    id: number = 0;
    nome: string = "";

    constructor(model?: MaterialGrupo){

        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
    }
}