
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { Calculadora001, Calculadora001Lashing, Material } from '@/core/models/geral'
import { Calculadora001Service } from '@/core/services/geral';
import { EnumeradoService } from "@/core/services/geral/EnumeradoService";
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';

@Component
export default class CadastroCalculadora001 extends Vue {
    @Prop() item!: Calculadora001;
    @Prop() private value!: string;

    tabActive: any = {};    
    itemOriginal!: Calculadora001;
    saveLoading: boolean = false;
    dialog: boolean = false;
    service: Calculadora001Service = new Calculadora001Service();
    enumeradoService = new EnumeradoService();
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    numberFieldRules: any[] = [(v: any) => (!isNaN(parseFloat(v)) && v >= 0) || "Campo obrigatório"];
    panel = [0,1,2,3,4,5,6];

    securingCapacity: any = {};
    results: any = {};

    $refs!: {
        form: HTMLFormElement,
    }

    methodForMotionsDefinitions: any[] = [];
    directions: any[] = [];

    selecionarMaterial: boolean = false;
    materialSelecionado: Material = new Material();
    
    lashing: Calculadora001Lashing = new Calculadora001Lashing();
    dialogLashing: boolean = false;
    editarLashing: boolean = false;
    lashingHeaders: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action'},
        { text: 'Lashing Nº', value: 'numero' },
        { text: 'MSL(MT)', value: 'msl' },
        { text: 'CS(MT)', value: 'cs' },
        { text: 'Vertial Securing Angle, α, (degree)', value: 'verticalSecuring' },
        { text: 'Horizontal Securing Angle, β, (degree)', value: 'horizontalSecuring' },
        { text: 'fX', value: 'fx' },
        { text: 'fY', value: 'fy' },
        { text: 'Level arm of securing force, c (m)', value: 'levelArmSecuringForce' },
        { text: 'Direction', value: 'direction' }
    ]

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }

        this.AtualizarReport();

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.enumeradoService.ListarMethodForMotionsDefinition().then(
            res => {
                this.methodForMotionsDefinitions = res.data;
            },
            err => AlertSimpleErr('Aviso!', err)
        );
        this.enumeradoService.ListarDirections().then(
            res => {
                this.directions = res.data
            },
            err => AlertSimpleErr('Aviso!', err)
        )
    }

    mounted() {
    }

    SelecionouMaterial(material: string, valor: number){
        this.item.cargoCharacteristics.materialCargoFloor = material;
        this.item.cargoCharacteristics.friction = valor;
    }

    AtualizarReport(){
        this.service.SecuringCapacity(this.item.lashings).then(
            res => this.securingCapacity = res.data,
            err => AlertSimpleErr("Aviso - SecuringCapacity", err)
        );

        this.service.Results(this.item).then(
            res => this.results = res.data,
            err => AlertSimpleErr("Aviso - Results", err)
        );
    }

    CoefCorrection(val: number){
        if(val < 0)
            this.item.cargoCharacteristics.appliedCoef = 0;
        else if(val > 1)
            this.item.cargoCharacteristics.appliedCoef = 1;
        else 
            this.item.cargoCharacteristics.appliedCoef = this.item.cargoCharacteristics.appliedCoef;    
        this.Calcular(1);
    }

    //PRECISA ATUALIZAR OS VALORES DOS ITENS QUANDO ALTERAR
    FrictionCorrection(val: number){
        if(val < 0)
            this.item.cargoCharacteristics.friction = 0;
        else if(val > 1)
            this.item.cargoCharacteristics.friction = 1;
        else 
            this.item.cargoCharacteristics.friction = this.item.cargoCharacteristics.friction;

        this.saveLoading = true;
        if(this.item.lashings.length > 0){
            this.item.lashings.forEach((lashing, index) => {
                this.service.CalcularItem(this.item.cargoCharacteristics.friction, lashing).then(
                    res => {
                        lashing.cs = res.data.cs;
                        lashing.fx = res.data.fx;
                        lashing.fy = res.data.fy;
                        if(index == this.item.lashings.length - 1){
                            this.saveLoading = false;
                        }
                        this.AtualizarReport();
                    },
                    err => AlertSimpleErr("Aviso!", err)
                )
            });
        }
        else{
            this.saveLoading = false;
        }

        this.AtualizarReport();
    }    

    Salvar(){
        if (this.$refs.form.validate()) {
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            this.saveLoading = true;
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);                    
                    this.Salvou();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.saveLoading = false;
            })
        }
    }

    //LASHING

    AdicionarLashing(){
        let lashing = new Calculadora001Lashing();
        lashing.numero = this.item.lashings.length + 1;
        lashing.direction = 1;
        this.item.lashings.push(lashing);
    }

    AbrirDialogCadastroLashing(item?: Calculadora001Lashing){
        if(item){
            this.editarLashing = true;
            this.dialogLashing = true;
            this.lashing = item;
        }
        else{
            this.lashing = new Calculadora001Lashing();
            this.lashing.calculadoraId = this.item.id;
            this.editarLashing = false;
            this.dialogLashing = true;
        }
    }

    ExcluirLashing(item: Calculadora001Lashing){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.lashings.indexOf(item);
                context.item.lashings.splice(index, 1);
            })
        }
        AlertExcludeQuestion(excluir, true);
    }

    SalvarLashing(){
        if(!this.editarLashing){
            if(this.item.lashings.length > 0){
                var lash = this.item.lashings.sort(x => x.numero)[this.item.lashings.length - 1];
                this.lashing.numero = lash.numero + 1;
            }
            else{
                this.lashing.numero = 1;
            }
            this.item.lashings.push(this.lashing);
        }

        this.item.lashings.forEach(lashing => {
            lashing.Calcular(this.item.cargoCharacteristics.friction);
        });
    }

    CalcularItem(item: Calculadora001Lashing){
        this.service.CalcularItem(this.item.cargoCharacteristics.friction, item).then(
            res => {
                let model = new Calculadora001Lashing(res.data);
                item.cs = model.cs;
                item.fx = model.fx;
                item.fy = model.fy;

                this.AtualizarReport();
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Calcular(layoutId: number){
        this.service.Calcular(layoutId, this.item).then(
            res => {
                //INPUT - CARGO MASS COEF
                //this.item.cargoCharacteristics.correctedMass = res.data.cargoCharacteristics;
                this.item.cargoCharacteristics.correctedMass = res.data.correctedMass;

                //INPUT - CARGO WIDTH HEIHG LEVERARMOFSTABLENESSLEFT
                //this.item.cargoCharacteristics.leverArmOfTipping = res.data.leverArmOfTipping;
                //this.item.cargoCharacteristics.leverArmOfStablenessLeft = res.data.leverArmOfStablenessLeft ? res.data.leverArmOfStablenessLeft : 0;
                //this.item.cargoCharacteristics.leverArmOfStablenessRigth = res.data.leverArmOfStablenessRigth ? res.data.leverArmOfStablenessRigth : 0;

                //INPUT - GRAVITY LONGACCEL TRANSACCEL
                this.item.accelerations.longAccelG = res.data.longAccelG;
                this.item.accelerations.transvAccelG = res.data.transvAccelG;

                //INPUT AIRDENSITY WINDVELOCITY TRANSVERSALAREA LATERALAREA
                this.item.windCharacteristics.windPressure = res.data.windPressure;
                this.item.windCharacteristics.windForceLongDirectionFwx = res.data.windForceLongDirectionFwx;
                this.item.windCharacteristics.windForceTransvDirectionFwy = res.data.windForceTransvDirectionFwy;
                //this.item.windCharacteristics.transversalArea = res.data.transversalArea;
                //this.item.windCharacteristics.lateralArea = res.data.lateralArea;

                this.item.landTransportationConditions.width = this.item.truckCharacteristics.width;

                this.item.longSlidingMT = res.data.longSlidingMT;
                this.item.transvSlidingMT = res.data.transvSlidingMT;
                this.item.transvTippingMTm = res.data.transvTippingMTm;

                this.AtualizarReport();

            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.dialog = false;
        this.$emit("fechou");
    }
}
