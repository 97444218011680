import { Calculadora001 } from ".";

export class Calculadora001Lashing implements Shared.IEntity{

    id: number = 0;
    calculadoraId: number = 0;
    calculadora!: Calculadora001;
    numero: number = 0;
    msl: number = 0;
    verticalSecuring: number = 0;
    horizontalSecuring: number = 0;
    levelArmSecuringForce: number = 0;
    direction: number = 0;

    //CALCULADOS
    cs: number = 0;
    fx: number = 0;
    fy: number = 0;

    constructor(model?: Calculadora001Lashing){

        if(!model)
            return;

        this.id = model.id;
        this.calculadoraId = model.calculadoraId;
        this.calculadora = model.calculadora;
        this.numero = model.numero;
        this.msl = model.msl;
        this.verticalSecuring = model.verticalSecuring;
        this.horizontalSecuring = model.horizontalSecuring;
        this.levelArmSecuringForce = model.levelArmSecuringForce;
        this.direction = model.direction;

        //CALCULADOS
        this.cs = model.cs;
        this.fx = model.fx;
        this.fy = model.fy;
    }

    public Calcular(friction: number){
        this.cs = this.msl > 0 ? parseFloat((this.msl / 1.35).toFixed(2)) : 0;
        this.fx = this.msl > 0 ? parseFloat((Math.cos(this.verticalSecuring * Math.PI / 180) * Math.sin(this.horizontalSecuring * Math.PI / 180) + (friction * Math.sin(this.verticalSecuring * Math.PI / 180))).toFixed(2)) : 0;
        this.fy = this.msl > 0 ? parseFloat((Math.cos(this.verticalSecuring * Math.PI / 180) * Math.cos(this.horizontalSecuring * Math.PI / 180) + (friction * Math.sin(this.verticalSecuring * Math.PI / 180))).toFixed(2)) : 0;
    }
}