
import { Material } from "@/core/models/geral";
import { MaterialService } from "@/core/services/geral";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SelecionaMaterial extends Vue {
    @Prop() private value!: boolean;
    
    dialog: boolean = false;
    loading: boolean = false;
    lista: Material[] = [];
    materialService: MaterialService = new MaterialService();

    headerMaterial: any[] = [
       { text: 'Material', value: 'nome', use: true },
       { text: 'Group', value: 'grupo.nome', use: true },
       { text: 'Dry', value: 'coeficienteSeco', use: true },
       { text: 'Wet', value: 'coeficienteMolhado', use: true },
   ];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    Carregar(){
        this.materialService.ListarTudo("Grupo").then(
            res => {
                this.lista = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Selecionar(nome: string, valor: number){
        this.$emit("selecionou", {
            nome,
            valor
        });
        this.Close();
    }

    Close(){
        this.lista = [];
        this.dialog = false;
        this.$emit("fechou");
    }
}
