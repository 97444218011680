
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { Usuario } from '@/core/models/geral'
import { PerfilUsuarioService, UsuarioService } from '@/core/services/geral';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { ArquivoService } from '@/core/services/shared';
import { PerfilUsuario } from '@/core/models/geral';
import { PageBase } from '@/core/models/shared';

@Component
export default class CadastroUsuario extends PageBase {
    @Prop() item!: Usuario;
    @Prop() private value!: string;

    itemOriginal!: Usuario;
    saveLoading: boolean = false;
    dialog: boolean = false;
    service: UsuarioService = new UsuarioService();
    valid: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    emailRules: any[] = [
        (v: any) => !!v || "Campo obrigatório",
        (v: any) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail deve ser válido'
    ]
    $refs!: {
        form: HTMLFormElement,
        file1: HTMLFormElement,
        file2: HTMLFormElement
    }

    foto: any = null;

    mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
    mskCelular = process.env.VUE_APP_MSK_CELULAR;

    perfil: PerfilUsuario[] = [];
    perfilService: PerfilUsuarioService = new PerfilUsuarioService();

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if(this.dialog){
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.perfilService.ListarTudo().then(
            res => {
                this.perfil = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    async LoadImage(){
        this.foto = this.$refs.file1.files[0];
        if (!this.foto)
            return;
        const arquivoService = new ArquivoService();
        const dados = await arquivoService.Ler(this.foto);
        this.item.foto = dados.replace(/^[^,]*,/, "");
    }
    RemoveImage(){
        this.item.foto = null;
        this.foto = null;
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            this.saveLoading = true;
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.saveLoading = false;
            })
        }
    }

    Close(){
        this.dialog = false;
    }
}
